@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
  scrollbar-width: thin;
}

option {
  color: black;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
  cursor: pointer;
}

/* calender state */

.react-calendar {
  width: 100%;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 16px;
  font-weight: bold;
  color: #1e1e1e;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #e6e6e6;
  color: #1e1e1e;
  border-radius: 6px;
}

.react-calendar__tile--now {
  background: #ffff76;
  border-radius: 6px;
  font-weight: bold;
  color: #1e1e1e;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
  border-radius: 6px;
}

.react-calendar__tile--hasActive {
  background: #76baff;
  border-radius: 6px;
  color: white;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
  border-radius: 6px;
}

.react-calendar__tile--active {
  background: #006edc;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
  border-radius: 6px;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
